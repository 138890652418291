@import '../../variables';
#mainteince-container{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: $bg-third;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    #maintenance-content{
        margin: 0;
        padding: 0 2vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        #maintenance-icon{
            margin: 0;
            padding: 0;
            font-size: 5rem;
            color: $primary;
        }
        #maintenance-title{
            margin: 0;
            padding: 0;
            font-size: 2rem;
            font-weight: 500;
            color: $text-gray;
        }
        #maintenance-description{
            margin: 0;
            padding: 0;
            font-size: 1rem;
            font-weight: 400;
            color: $text-gray;
        }
    }
}
@media screen and (min-width: 768px) {
    #web-app-container{
        #menu-nav{
            position: fixed;
            z-index: 100;
            top: 0;
            left: 0;
            padding-right: 0;
            padding-left: 0;
            #first-header{
                background-color: $primary;
                padding-top: 6px;
                padding-bottom: 6px;
                justify-content: flex-end;
                #header-phone-list{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    text-align: center;
                    list-style: none;
                    .header-phone-item{
                        margin: 0 1vw 0 0;
                        padding: 0 5px 0 0;
                        a{
                            margin: 0;
                            padding: 0;
                            display: block;
                            width: 100%;
                            color: $text-white;
                            text-decoration: none;
                            .header-phone-icon{ 
                                margin-right: 5px;
                                i{
                                    font-size: 16px;
                                }
                            }
                            .header-phone-text{
                                margin: 0;
                                padding: 0;
                                font-size: 13px;
                            }
                        }
                    }
                }
                #header-social-media-list{
                    padding: 0;
                    margin:0;
                    list-style: none;
                    .header-social-media-item{
                        margin: 0 0 0 25px;
                        padding: 0;
                        height: 70%;
                        a{
                            color: $text-white;
                            height: 70%;
                            img{
                                height: 24px;
                                transition: .3s;
                                &:hover{
                                    transform: scale(1.2) !important;
                                    color: $text-white !important;
                                }
                            }
                        }
                    }
                }
            }
            #secondary-header{
                background-color: $bg-third;
                padding-top: 12px;
                padding-bottom: 12px;
                #header-business-logo{
                    padding: 0;
                    margin: 0;
                    width: 200px;
                    cursor: pointer;
                }
                #header-navigation-list{
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    width: inherit;
                    list-style: none;
                    .header-navigation-list-item{
                        margin: 0;
                        padding: 0;
                        a{
                            color: $text-gray;
                            text-decoration: none;
                            transition: .3s;
                            font-weight: 500;
                            font-size: 1.1em;
                            &:hover{
                                color: $primary;
                                text-decoration: none;
                            }
                        }
                        &.header-navigation-normal{
                            margin: 0 0 0 3vw;
                            
                        }
                        transition: .3s;
                        &:hover{
                            transform: scale(1.05);
                        }
                    }
                   
                    #header-quote-button{
                        background-color: $primary;
                        color: $text-white;
                        padding: 15px 25px;
                        font-weight: 500;
                    }
                }
            }
        }
        #home-container{
            min-height: 100vh;
            padding: 0 0 0 0;
            margin: 0;
        }
        /*Testimonials*/
        #testimonials-container{
            margin: 0;
            padding: 12vh 0;
            width: 100%;
            #testimonials-title{
                margin: 0;
                padding: 0;
                text-align: center;
                font-size: 2.5em;
                font-weight: bold;
                color: $primary;
            }
            #testimonials-list{
                margin: 0 auto;
                padding: 6vh 0 2vh;
                list-style: none;
                width: 100%;
                height: 50vh;
                text-align: center;
                display: flex;
                justify-content: center;
                .testimonials-list-item{
                    margin: 0 2vw;
                    padding: 5vh 2vw;
                    width: 45vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    align-items: center;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    .testimonials-list-list-text{
                        margin: auto 0 2vh 0; 
                        padding: 0;
                        width: 100%;
                        text-align: center;
                        font-size: 1.1em;
                        font-weight: 500;
                        color: $text-gray;
                    }
                    .testimonials-list-item-author-container{
                        margin: auto 0 0 0;
                        padding: 0;
                        .testimonials-list-item-author-image{
                            width: 80px;
                            height: 80px;
                            object-fit: cover;
                            border-radius: 50%;
                            margin: 0 10px 0 0;
                        }
                        .testimonailas-list-item-text{
                            margin: 0 0 0 1vw;
                            padding: 0;
                            p{
                                margin: 0;
                                padding: 0;
                                width: 100%;
                                color: #323234;
                                text-align: left;
                                font-weight: 600;
                                font-size: 1em;
                            }
                        }
                    }
                }
            }
            #testimonials-list-indicators{
                margin: 2vh auto 0 auto;
                padding: 0;
                display: block;
                list-style: none;
                text-align: center;
                .testimonials-list-indicators-item{
                    margin: 0 3px;
                    padding: 0;
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    cursor: pointer;
                    transition: .3s;
                    &:hover, &.active-indicator{
                        background-color: $text-gray !important;
                    }
                    &.non-active-indicator{
                        background-color: #E6E7E7;
                    }
                }
            }
        }
        
        /*Blogs*/
        #blogs-container{
            margin: 0;
            padding: 10vh 0;
            width: 100%;
            background-image: url('/images/blog/bg.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            .arrows-padding{
                padding: 0 3vw;
            }
            #title-container{
                margin: 0;
                padding: 0 7vw;
                width: 100%;
                h1{
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    font-size: 2.5em;
                    font-weight: bold;
                    color: $text-white;
                }
                #title-line{
                    margin: 0 0 0 4vw;
                    padding: 0;
                    width: 100%;
                    height: 3px;
                    background-color: $text-white;
                }
            }
            #blog-content{
                margin: 0;
                padding: 5vh 0 0 0;
                width: 100%;
                display: flex;
                justify-content: space-between;
                .blog-arrow{
                    margin: 0;
                    padding: 0;
                    color: #fff;
                    font-size: 25px;
                    transition: .3s;
                    cursor: pointer;
                    &:hover{
                        transform: scale(1.1);
                    }
                }
                #blog-content-title-container{
                    width: 40vw;
                    max-width: 500px;
                    #blog-content-title{
                        margin: 0;
                        padding: 0 0 2vh 0;
                        font-size: 1.8em;
                        font-weight: bold;
                        color: $text-white;
                    }
                    #blog-content-data{
                        margin: 0;
                        padding: 0 0 2vh 0;
                        font-size: 1.2em;
                        font-weight: 400;
                        line-height: 22px;
                        color: $text-white;
                    }
                    #blog-view-more{
                        padding: 8px 80px;
                        font-size: 1.1rem;
                        font-weight: 600;
                        color: $primary;
                        background-color: #fff;
                        cursor: pointer;
                        transition: .3s;
                        &:hover{
                            transform: scale(1.05);
                        }
                    }
                }
                #blog-content-image{
                    width: 45%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        /*Certifications*/
        #certifications-allies-container{
            margin: 0;
            padding: 12vh 0;
            #certifications-allies-title{
                margin: 0;
                padding: 0;
                text-align: center;
                font-size: 2.5em;
                font-weight: bold;
                color: $primary;
            }
            #certifications-allies-description{
                margin: 0 auto;
                padding: 2vh 0;
                text-align: center;
                font-size: 1.2em;
                font-weight: 400;
                color: #323234;
                line-height: 22px;
                width: 60%;
            }
            #certifications-allies-list{
                max-width: 100%;
                height: 200px;
                list-style: none;
                white-space: nowrap;
                overflow-x: auto;
                overflow-y: hidden;
                transition: .3s;
                text-align: center;
                margin: 0 auto;
                padding: 2vh 0 0 0;
                display:block;
                text-align: center;
                
                &::-webkit-scrollbar
                {
                    width: 100px;
                    height: 4px;
                    background-color: #000;
                }
                .certifications-allies-list-item{
                    margin: 0;
                    padding: 0 2vw;
                    cursor: pointer;
                    transition: .3s;
                    height: min-content;
                    display: inline-block;
                    vertical-align: middle;
                    &:hover{
                        transform: rotate(2deg);
                    }
                    .certifications-allies-list-item-image{
                        height: 120px;
                        object-fit: cover;
                        align-self: center;
                    }
                }
            }
        }
        #footer-container{
            background-color: $primary;
            #footer-logo{
                margin: 0;
                padding: 14vh 0;
                display: block;
                width: 25%;
                max-width: 250px;
                cursor: pointer;
            }
            #footer-content{
                margin: 0;
                padding: 0;
                width: 100%;
                display: flex;
                justify-content: end;
                .footer-col{
                    padding: 0 35px;
                    margin: 0;
                    .footer-title,
                    .footer-link{
                        color: $text-white;
                        margin: 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        text-decoration: none;
                        cursor: pointer;
                        transition: .3s;
                        margin-bottom: 4px;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                    .footer-title{
                        font-weight: 600;
                        font-size: 1.3em;
                    }
                    .footer-link{
                        font-size: 0.9rem;
                        font-weight: 300;
                    }	
                    #footer-social-media-list{
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        display: flex;
                        justify-content: start;
                        .footer-social-media-item{
                            margin: 0;
                            padding: 0 10px 0 0;
                            a{
                                margin: 0;
                                padding: 0;
                                display: block;
                                img{
                                    width: 30px;
                                }
                            }
                        }
                    }
                    #footer-address-list{
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        .footer-address-item{
                            margin: 10px 0;
                            padding: 0;
                            display: block;
                            width: 100%;
                            .footer-address-link{
                                color: $text-white;
                                text-decoration: none;
                                transition: .3s;
                                &:hover{
                                    color: $text-white;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                    #footer-phone-list{
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        .footer-phone-item{
                            margin: 3px 0;
                            padding: 0;
                            display: block;
                            width: 100%;
                            
                        }
                    }
                }
            }
        }
    }
    
}
@media screen and (max-width: 768px) {
    #web-app-container{
        #menu-nav {
            width: 100vw;
            top: 0;
            position: fixed;
            left: 0;
            z-index:1000;
            padding: 2vh 0 !important;
            transition: .3s;
            background-color: rgba(255, 255, 255, 0.98) !important;
            #burger-menu{
                font-size: 24px;
                color: $primary;
                cursor: pointer;
            }
            #header-business-logo{
                padding: 0;
                margin: 0 20vw 0 0;
                width: 150px;
                cursor: pointer;
            }
            #shopping-cart-badge-container{
                margin: auto 0;
                &:hover #shopping-cart-badge, 
                &.added-to-cart #shopping-cart-badge{
                    background-color: $primary !important;
                }
                #shopping-cart-badge{
                    position: relative;
                    top: -25%;
                    right: 0;
                    width: 1.2rem;
                    height: 1.2rem;
                    border-radius: 50%;
                    background-color: $secondary !important;
                    color: #fff;
                    font-size: 0.6rem;
                    font-weight: 500;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transform: translate(70%, -100%);
                    transition: .3s;
                    z-index: 11;
                }
                #shopping-cart-list{
                    position: absolute;
                    display: none;
                    padding: 10px 5px;
                    top: 0;
                    right: 0;
                    width: 25vw;
                    max-height: 20vh;
                    width: 90%;
                    z-index: 10;
                    transform: translate(-3%, 8vh);
                    background-color: rgba(255, 255, 255, 0.98) !important;
                    -webkit-box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
                    -moz-box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
                    box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
                    .shopping-cart-list-item{
                        margin: 0 0 10px 0;
                        padding: 0 0 10px 0;
                        display: block;
                        width: 100%;
                        .shopping-cart-list-image-container,
                        .shopping-cart-list-name,
                        .shopping-cart-list-quantity,
                        .product-remove-button{
                            display: inline-block;
                            vertical-align: middle;
                            
                        }
                        .shopping-cart-list-image-container{
                            width: 17vw;
                            height: 22vw;
                            background-position: center;
                            background-size: 100% auto;
                            background-repeat: no-repeat;
                            margin-right: 10px;
                        }
                        .shopping-cart-list-name{
                            margin: 0;
                            padding: 0;
                            font-size: 12px;
                            width: 40%;
                            letter-spacing: 0.3px;
                            color: $primary !important;
                        }
                        .shopping-cart-list-quantity{
                            padding: 0 10px;
                            border: 2px solid $text-gray;
                            font-size: 12px;
                            width: 30%;
                            .product-quantity-input{
                                border: none;
                                padding: 0;
                                font-size: 12px;
                            }
                        }
                        .product-remove-button{
                            font-size: 14px;
                            margin: 0 0 0 3%;
                            padding: 0;
                            cursor: pointer;
                            border: none;
                            background-color: transparent;
                            color: $primary;
                            transition: .3s;
                            color: #000;
                            &:hover{
                                color: $danger;
                            }
                        }
                    }
                }
            }
            #menu-contact {
                background-color: #000;
                color: #ffffff;
                padding-top: 2px;
                padding-bottom: 1px;
                display: none !important;
            }
            #emergent-menu{
                background-color: #fff;
                width: 100vw;
                min-height: 30vh;
                position: relative;
                top: 1vh;
                left: 50%;
                transform: translate(-50%, 0);
                &.nav-list-close{
                    display: none;
                }
                &.nav-list-open{
                    display: flex;
                }
                #emergent-menu-title {
                    letter-spacing: 3.2px;
                    font-size: 1.95rem;
                }
                #emergent-menu-content-1{
                    .emergent-list{
                        &.emergent-categories-list{
                            display: inline-block;
                            vertical-align: top;
                            width: 33%;
                        }
                        transition: .3s;
                        list-style: none;
                        &:hover .emergent-list-category{
                            color: $secondary;
                        }
                        .emergent-list-category,
                        .emergent-list-sub-category{
                            text-decoration: none;
                            font-size: 0.7rem;
                            cursor: pointer;
                            color: $text-gray;
                            transition: .3s;
                            &:hover{
                                color: $secondary;
                            }
                        }
                        .emergent-list-category{
                            font-weight: 500;
                            text-transform: uppercase;
                            letter-spacing: 1.68px;
                            
                        }
                        .emergent-list-sub-category{
                            font-weight: 200;
                            letter-spacing: 1.68px;
                        }
                        &.emergent-brand-list{
                            display: block;
                            width: 100%;
                            .emergent-brand-list-item{
                                display: inline-block;
                                vertical-align: top;
                                width: 33%;
                                cursor: pointer;
                                transition: .3s;
                                &:hover .emergent-brand-list-item-text{
                                    color: $secondary;
                                }
                                .emergent-brand-list-item-text{
                                    transition: .3s;
                                    text-decoration: none;
                                    color: $primary;
                                    font-weight: 200;
                                    letter-spacing: 1.68px;
                                    font-size: 0.8rem;
                                }
                            }
                        }
                        
                    }
                }
                #emergent-menu-content-2{
                    #emergent-menu-category-image{
                        max-width: 100%;
                        max-height: 30vh;
                        margin: 0 0 0 auto;
                        padding: 0;
                        display: block;
                    }
                    #emergent-menu-brand-image{
                        width: 60%;
                        min-width: 100px;
                        max-width: 250px;
                        margin: 0 0 0 auto;
                        padding: 0;
                        display: block;
                    }
                }
                
            }
            &.menu-white {
                background-color: #ffffff;
                color: $text-gray;
                #nav-search {
                    border-bottom: 2px solid $text-gray;
                    input {
                        &::placeholder {
                            color: $text-gray;
                        }
                        
                    }
                }
                #ul-nav {
                    .nav-list {
                        color: $text-gray;
                        
                    }
                }
                #nav-user-bag {
                    color: $text-gray;
                }
            }
            &.menu-transparent {
                background-color: transparent;
                color: $text-gray;
                #nav-search {
                    border-bottom: 2px solid $text-gray;
                    input {
                        color: #fff;
                        &::placeholder {
                            color: $text-gray;
                        }
                    }
                }
                .header-logo {
                    filter: brightness(200%) contrast(200%) saturate(0%) invert(100%);
                    cursor: pointer;
                }
                #ul-nav {
                    .nav-list {
                        color: $text-gray;
                        cursor: pointer;
                    }
                }
                #nav-user-bag {
                    #nav-opt-register{
                        filter: brightness(200%) contrast(200%) saturate(0%) invert(100%);
                    }
                    #nav-opt-login {
                        filter: brightness(200%) contrast(200%) saturate(0%) invert(100%);
                    }
                    .nav-opt{
                        color: $text-gray;
                        img{
                            filter: brightness(200%) contrast(200%) saturate(0%) invert(100%);
                        }
                        cursor: pointer;
                    }
                }
                #nav-opt-register{
                    color: #fff !important;
                }
            }
            .icon {
                display: inline-block;
                width: fit-content;
                margin-left: 3rem;
                cursor: pointer;
            }
            
            .icon img {
                height: 0.8rem;
            }
            #nav-hours h6 {
                margin-right: 4rem;
                margin-bottom: 0;
                display: inline-block;
                font-size: 0.6rem;
                font-weight: 400;
            }
            .nav-list {
                display: inline-block;
                width: fit-content;
                margin-right: 1.2rem;
                margin-left: 1.2rem;
                font-size: 0.6rem;
                font-weight: 400;
                cursor: pointer;
            }
            #nav-search {
                margin-top: 1rem;
                width: fit-content;
                input {
                    width: 12rem;
                    border: none;
                    font-size: 0.6rem;
                    font-weight: 400;
                    background-color: transparent;
                    &:focus {
                        outline: none;
                    }
                }
            }
            #ul-nav {
                padding-bottom: 0.3rem;
            }
            .header-logo {
                display: block;
                margin: 0 auto;
                cursor: pointer;
                width: 30vw;
            }
            #nav-user-bag {
                .nav-opt {
                    height: 1.5rem;
                    font-size: 1.5rem;
                    margin-left: 2.4rem;
                    margin-top: 0.3rem;
                    cursor: pointer;
                    #bag_img{
                        height: 1.5rem;
                        vertical-align: top;
                    }
                }
                #nav-opt-register{
                    color: #000 !important;
                }
                #nav-opt-login{
                    margin: auto 0;
                }
            }
            #responsive-menu{
                margin: 0;
                padding: 0 10px;
                width: 70%;
                height: 100vh;
                background-color: #FFF;
                position: fixed;
                display: none;
                top: 0;
                left: -100%;
                z-index: 100;
                -webkit-box-shadow: 0px 0px 5px 0px rgba(153,153,153,1);
                -moz-box-shadow: 0px 0px 5px 0px rgba(153,153,153,1);
                box-shadow: 0px 0px 5px 0px rgba(153,153,153,1);
                #responsive-close-opt{
                    margin: 0;
                    padding: 0;
                    display: block;
                    background-color: transparent;
                    cursor: pointer;
                    position: absolute;
                    top: 1%;
                    right: 3%;
                    border: none;
                    font-size: 1.5em;
                }
                #responsive-header-logo{
                    margin: 4% auto 0 auto;
                    display: block;
                    width: 80%;
                }
                #responsive-ul-nav{
                    margin: 4vh 0 0 0;
                    padding: 0;
                    display: block;
                    list-style: none;
                    text-align: center;
                    max-height: 80vh;
                    .nav-list{
                        margin: 0 0 1vh;
                        padding: 0;
                        display: block;
                        width: 100%;
                        font-size: 1em;
                        font-weight: 400;
                        cursor: pointer;
                        color: $primary;
                        &:hover{
                            color: $secondary;
                        }
                        span{
                            font-size: 18px;
                        }
                        .emergent-data{
                            margin: 0;
                            padding: 0;
                            display: block;
                            width: 100%;
                            .emergent-list{
                                margin: 0;
                                padding: 0;
                                display: block;
                                width: 100%;
                                list-style: none;
                                .emergent-list-category{
                                    text-decoration: none;
                                    font-size: 14px;
                                    margin: 0;
                                    padding: 0;
                                    display: block;
                                    width: 100%;
                                }
                                .emergent-list-item{
                                    margin: 0;
                                    padding: 0;
                                    display: block;
                                    width: 100%;
                                    .emergent-list-sub-category{
                                        margin: 0;
                                        padding: 0;
                                        display: block;
                                        width: 100%;
                                        text-decoration: none;
                                        font-size: 12px;
                                    }
                                    
                                }
                            }
                            
                        }
                    }
                }
            }
        }
        #home-container{
            min-height: 100vh;
            padding: 0 0 0 0;
            margin: 0;
        }
        /*Testimonials*/
        #testimonials-container{
            margin: 0;
            padding: 5vh 0;
            width: 100%;
            #testimonials-title{
                margin: 0;
                padding: 0;
                text-align: center;
                font-size: 1.5em;
                font-weight: bold;
                color: $primary;
            }
            #testimonials-list{
                margin: 0 auto;
                padding: 2vh 0;
                list-style: none;
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                .testimonials-list-item{
                    margin: 1vh auto;
                    padding: 5vh 3vw;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    align-items: center;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    .testimonials-list-list-text{
                        margin: auto 0 2vh 0; 
                        padding: 0;
                        width: 100%;
                        text-align: center;
                        font-size: 1.1em;
                        font-weight: 500;
                        color: $text-gray;
                    }
                    .testimonials-list-item-author-container{
                        margin: auto 0 0 0;
                        padding: 0;
                        .testimonials-list-item-author-image{
                            width: 80px;
                            height: 80px;
                            object-fit: cover;
                            border-radius: 50%;
                            margin: 0 10px 0 0;
                        }
                        .testimonailas-list-item-text{
                            margin: 0 0 0 1vw;
                            padding: 0;
                            p{
                                margin: 0;
                                padding: 0;
                                width: 100%;
                                color: #323234;
                                text-align: left;
                                font-weight: bold;
                                font-size: 1em;
                                
                            }
                        }
                    }
                }
            }
            #testimonials-list-indicators{
                margin: 2vh auto 0 auto;
                padding: 0;
                display: block;
                list-style: none;
                text-align: center;
                .testimonials-list-indicators-item{
                    margin: 0 3px;
                    padding: 0;
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    cursor: pointer;
                    transition: .3s;
                    &:hover, &.active-indicator{
                        background-color: $text-gray !important;
                    }
                    &.non-active-indicator{
                        background-color: #E6E7E7;
                    }
                }
            }
        }
        
        /*Blogs*/
        #blogs-container{
            margin: 0;
            padding: 10vh 0;
            width: 100%;
            background-image: url('/images/blog/bg.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            #title-container{
                margin: 0;
                padding: 0;
                width: 100%;
                h1{
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    font-size: 1.8em;
                    font-weight: bold;
                    color: $text-white;
                }
                #title-line{
                    margin: 0 0 0 4vw;
                    padding: 0;
                    width: 100%;
                    height: 3px;
                    background-color: $text-white;
                }
            }
            #blog-content{
                margin: 0;
                padding: 5vh 0 0 0;
                width: 100%;
                display: flex;
                flex-direction: column;
                .blog-arrow{
                    display: none;
                }
                #blog-content-title-container{
                    order: 2;
                    width: 100%;
                    #blog-content-title{
                        margin: 0;
                        padding: 2vh 0 2vh 0;
                        font-size: 1.5em;
                        font-weight: bold;
                        color: $text-white;
                        text-align: center;
                    }
                    #blog-content-data{
                        margin: 0;
                        padding: 2vh 0 2vh 0;
                        font-size: 1.1em;
                        font-weight: 400;
                        color: $text-white;
                        text-align: center;
                        line-height: 20px;
                    }
                    #blog-view-more{
                        margin: 0 auto 0 auto;
                        display: block;
                        font-size: 0.9rem;
                        font-weight: bold;
                        color: $primary;
                        background-color: #fff;
                        font-weight: bold;
                        cursor: pointer;
                        transition: .3s;
                        width: 200px;
                        &:hover{
                            transform: scale(1.05);
                        }
                    }
                }
                #blog-content-image{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    order: 1;
                }
            }
        }
        /*Certifications*/
        #certifications-allies-container{
            margin: 0;
            padding: 10vh 0;
            #certifications-allies-title{
                margin: 0 0 2vh 0;
                padding: 0;
                text-align: center;
                font-size: 1.5em;
                font-weight: bold;
                color: $primary;
                width: 100%;
            }
            #certifications-allies-description{
                display: none;
            }
            #certifications-allies-list{
                max-width: 100%;
                height: 130px;
                list-style: none;
                white-space: nowrap;
                overflow-x: auto;
                overflow-y: hidden;
                transition: .3s;
                text-align: center;
                margin: 0 auto;
                padding: 2vh 0 0 0;
                display:block;
                text-align: center;
                
                &::-webkit-scrollbar
                {
                    width: 100px;
                    height: 4px;
                    background-color: #000;
                }
                .certifications-allies-list-item{
                    margin: 0;
                    padding: 0 2vw;
                    cursor: pointer;
                    transition: .3s;
                    height: min-content;
                    display: inline-block;
                    vertical-align: middle;
                    &:hover{
                        transform: rotate(2deg);
                    }
                    .certifications-allies-list-item-image{
                        height: 80px;
                        object-fit: cover;
                        align-self: center;
                    }
                }
            }
        }
        #footer-container{
            background-color: $primary;
            padding: 5vh 0;
            #footer-logo{
                display: none;
            }
            #footer-content{
                margin: 0;
                padding: 0;
                width: 100%;
                display: block;
                text-align: left;
                .footer-col{
                    padding: 2vh 4% 0 5%;
                    margin: 0;
                    width: 49%;
                    display: inline-block;
                    vertical-align: top;
                    .footer-title,
                    .footer-link{
                        color: $text-white;
                        margin: 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        text-decoration: none;
                        cursor: pointer;
                        transition: .3s;
                        margin-bottom: 4px;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                    .footer-title{
                        font-weight: 500;
                        font-size: 1.4em;
                    }
                    .footer-link{
                        font-size: 1em;
                        font-weight: 300;
                    }	
                    #footer-social-media-list{
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        display: flex;
                        justify-content: center;
                        .footer-social-media-item{
                            margin: 0;
                            padding: 0 10px 0 0;
                            a{
                                margin: 0;
                                padding: 0;
                                display: block;
                                img{
                                    width: 30px;
                                }
                            }
                        }
                    }
                    #footer-address-list{
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        .footer-address-item{
                            margin: 10px 0;
                            padding: 0;
                            display: block;
                            width: 100%;
                            .footer-address-link{
                                color: $text-white;
                                text-decoration: none;
                                transition: .3s;
                                &:hover{
                                    color: $text-white;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                    #footer-phone-list{
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        .footer-phone-item{
                            margin: 3px 0;
                            padding: 0;
                            display: block;
                            width: 100%;
                            
                        }
                    }
                }
            }
        }
    }
}
